.container {
  position: absolute;
  padding: 16px;
  z-index: 2;
}

.container button {
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 1px 4px;
  border: 0;
  background: none;
  cursor: pointer;
}

.container button:last-of-type {
  margin-right: 0;
}

.active {
  color: #58595b;
}

.container button:disabled {
  cursor: unset;
  color: rgba(0, 0, 0, 0.38);
}

.container button.active:disabled {
  color: rgba(0, 0, 0, 0.38);
}
