.ProseMirror {
  padding: 144px 16px 16px 16px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.ProseMirror-focused {
  padding: 143px 15px 15px 15px;
  border-width: 2px;
  border-color: #58595b;
  outline: none;
}

.ProseMirror[contenteditable='false'] {
  border-color: rgba(0, 0, 0, 0.26);
}

.ProseMirror-focused[contenteditable='false'] {
  padding: 144px 16px 16px 16px;
  border-width: 1px;
}

@media (min-width: 384px) {
  .ProseMirror,
  .ProseMirror-focused[contenteditable='false'] {
    padding-top: 96px;
  }

  .ProseMirror-focused {
    padding-top: 95px;
  }
}

@media (min-width: 1000px) {
  .ProseMirror,
  .ProseMirror-focused[contenteditable='false'] {
    padding-top: 48px;
  }

  .ProseMirror-focused {
    padding-top: 47px;
  }
}
